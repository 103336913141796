const ChevronUp = (props) => (
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="chevron-up">
      <path 
        id="Icon" 
        d="M15 12.5L10 7.5L5 12.5" 
        stroke="#667085" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
export default ChevronUp;