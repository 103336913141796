import { 
  SPreloaderWrapper, 
  SPreloaderContainer, 
  SPreloader
} from './styled';
import loader from '../../assets/images/loader.gif'
const Preloader = ({isContentPreloader})=> (
  <SPreloaderWrapper 
    isContentPreloader={isContentPreloader}
  >
    <SPreloaderContainer>
      <SPreloader src={loader} alt="preloader"/> 
    </SPreloaderContainer>
  </SPreloaderWrapper>
)
export default Preloader;  