import styled from "styled-components";

export const InfoIconContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 25px;
  & .customTooltip {
    display: none;
  }
  &:hover  .customTooltip {
    display: flex;
  }
`