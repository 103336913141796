const MonoCreatorLogo = (props) => (
  <svg
    width="192"
    height="25"
    viewBox="0 0 192 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="MonoCreator">
      <path
        id="Vector"
        d="M187.018 10.3174C187.934 8.26781 189.594 7.24304 192 7.24304V10.2513C190.637 10.1852 189.466 10.5598 188.487 11.3753C187.508 12.1907 187.018 13.502 187.018 15.3091V24.0695H184.24V7.54054H187.018V10.3174Z"
        fill="#101018"
      />
      <path
        id="Vector_2"
        d="M178.506 21.9854C176.866 23.6603 174.876 24.4978 172.534 24.4978C170.192 24.4978 168.201 23.6603 166.562 21.9854C164.944 20.3105 164.135 18.2499 164.135 15.8036C164.135 13.3573 164.944 11.2967 166.562 9.62178C168.201 7.94684 170.192 7.10938 172.534 7.10938C174.876 7.10938 176.866 7.94684 178.506 9.62178C180.145 11.2967 180.965 13.3573 180.965 15.8036C180.965 18.2499 180.145 20.3105 178.506 21.9854ZM172.534 21.6879C174.131 21.6879 175.472 21.1259 176.558 20.0019C177.643 18.878 178.186 17.4785 178.186 15.8036C178.186 14.1287 177.643 12.7292 176.558 11.6052C175.472 10.4813 174.131 9.91931 172.534 9.91931C170.959 9.91931 169.628 10.4813 168.542 11.6052C167.456 12.7292 166.913 14.1287 166.913 15.8036C166.913 17.4785 167.456 18.878 168.542 20.0019C169.628 21.1259 170.959 21.6879 172.534 21.6879Z"
        fill="#101018"
      />
      <path
        id="Vector_3"
        d="M162.552 10.3179H158.305V19.2766C158.305 20.092 158.454 20.676 158.752 21.0287C159.071 21.3592 159.55 21.5356 160.189 21.5576C160.827 21.5576 161.615 21.5355 162.552 21.4915V24.07C160.125 24.4006 158.347 24.2022 157.219 23.4749C156.091 22.7256 155.527 21.3262 155.527 19.2766V10.3179H152.365V7.54106H155.527V3.77246L158.305 2.91296V7.54106H162.552V10.3179Z"
        fill="#101018"
      />
      <path
        id="Vector_4"
        d="M146.877 7.53914H149.655V24.0681H146.877V21.2251C145.493 23.4069 143.481 24.4978 140.841 24.4978C138.606 24.4978 136.7 23.6603 135.125 21.9854C133.55 20.2884 132.762 18.2278 132.762 15.8036C132.762 13.3793 133.55 11.3298 135.125 9.65484C136.7 7.95788 138.606 7.10938 140.841 7.10938C143.481 7.10938 145.493 8.20028 146.877 10.3821V7.53914ZM141.193 21.7209C142.811 21.7209 144.163 21.159 145.248 20.035C146.334 18.889 146.877 17.4785 146.877 15.8036C146.877 14.1287 146.334 12.7292 145.248 11.6052C144.163 10.4592 142.811 9.88624 141.193 9.88624C139.596 9.88624 138.255 10.4592 137.169 11.6052C136.083 12.7292 135.54 14.1287 135.54 15.8036C135.54 17.4785 136.083 18.889 137.169 20.035C138.255 21.159 139.596 21.7209 141.193 21.7209Z"
        fill="#101018"
      />
      <path
        id="Vector_5"
        d="M117.017 17.1259C117.294 18.6025 117.943 19.7485 118.965 20.5639C120.008 21.3793 121.286 21.7871 122.797 21.7871C124.905 21.7871 126.438 20.9827 127.396 19.3738L129.759 20.7623C128.205 23.2526 125.863 24.4978 122.734 24.4978C120.2 24.4978 118.135 23.6824 116.538 22.0515C114.963 20.3986 114.175 18.316 114.175 15.8036C114.175 13.3132 114.952 11.2416 116.506 9.58871C118.06 7.93584 120.072 7.10938 122.542 7.10938C124.884 7.10938 126.789 7.96888 128.258 9.68791C129.748 11.3849 130.493 13.4344 130.493 15.8366C130.493 16.2554 130.462 16.6851 130.398 17.1259H117.017ZM122.542 9.82014C121.052 9.82014 119.817 10.2609 118.837 11.1424C117.858 12.0019 117.251 13.159 117.017 14.6135H127.683C127.449 13.0488 126.853 11.8587 125.895 11.0433C124.937 10.2278 123.819 9.82014 122.542 9.82014Z"
        fill="#101018"
      />
      <path
        id="Vector_6"
        d="M108.242 10.3174C109.158 8.26781 110.818 7.24304 113.224 7.24304V10.2513C111.861 10.1852 110.69 10.5598 109.711 11.3753C108.732 12.1907 108.242 13.502 108.242 15.3091V24.0695H105.464V7.54054H108.242V10.3174Z"
        fill="#101018"
      />
      <path
        id="Vector_7"
        d="M92.6506 24.5C89.2654 24.5 86.466 23.343 84.2519 21.0289C82.059 18.7149 80.9624 15.8719 80.9624 12.5C80.9624 9.1281 82.059 6.28513 84.2519 3.97107C86.466 1.65703 89.2654 0.5 92.6506 0.5C94.6943 0.5 96.5679 1.0069 98.2711 2.02067C99.9955 3.03443 101.337 4.40083 102.295 6.11983L99.7079 7.67357C99.0694 6.39533 98.1113 5.38153 96.834 4.63223C95.5779 3.86087 94.1833 3.4752 92.6506 3.4752C90.0743 3.4752 87.9669 4.3347 86.3274 6.05373C84.7096 7.77273 83.9004 9.9215 83.9004 12.5C83.9004 15.0565 84.7096 17.1942 86.3274 18.9132C87.9669 20.6322 90.0743 21.4917 92.6506 21.4917C94.1833 21.4917 95.5779 21.1171 96.834 20.3678C98.1113 19.5964 99.0694 18.5826 99.7079 17.3264L102.295 18.8471C101.358 20.5661 100.027 21.9435 98.303 22.9793C96.5785 23.9931 94.6943 24.5 92.6506 24.5Z"
        fill="#101018"
      />
      <path
        id="Vector_8"
        d="M76.1216 21.9854C74.4822 23.6603 72.4916 24.4978 70.1499 24.4978C67.8079 24.4978 65.8174 23.6603 64.178 21.9854C62.5601 20.3105 61.7512 18.2499 61.7512 15.8036C61.7512 13.3573 62.5601 11.2967 64.178 9.62178C65.8174 7.94684 67.8079 7.10938 70.1499 7.10938C72.4916 7.10938 74.4822 7.94684 76.1216 9.62178C77.761 11.2967 78.5805 13.3573 78.5805 15.8036C78.5805 18.2499 77.761 20.3105 76.1216 21.9854ZM70.1499 21.6879C71.7465 21.6879 73.0879 21.1259 74.1736 20.0019C75.2592 18.878 75.8023 17.4785 75.8023 15.8036C75.8023 14.1287 75.2592 12.7292 74.1736 11.6052C73.0879 10.4813 71.7465 9.91931 70.1499 9.91931C68.5743 9.91931 67.2438 10.4813 66.1579 11.6052C65.0722 12.7292 64.5294 14.1287 64.5294 15.8036C64.5294 17.4785 65.0722 18.878 66.1579 20.0019C67.2438 21.1259 68.5743 21.6879 70.1499 21.6879Z"
        fill="#101018"
      />
      <path
        id="Vector_9"
        d="M52.5722 7.10938C54.4458 7.10938 55.9467 7.72648 57.0748 8.96061C58.2033 10.1727 58.7674 11.8256 58.7674 13.9193V24.0681H55.9892V14.0846C55.9892 12.7402 55.6381 11.7044 54.9354 10.9771C54.2328 10.2278 53.2641 9.85318 52.0294 9.85318C50.6454 9.85318 49.5279 10.305 48.6761 11.2085C47.8247 12.0901 47.3988 13.4565 47.3988 15.3077V24.0681H44.6206V7.53914H47.3988V9.91931C48.5273 8.04601 50.2517 7.10938 52.5722 7.10938Z"
        fill="#101018"
      />
      <path
        id="Vector_10"
        d="M38.8865 21.9854C37.2471 23.6603 35.2565 24.4978 32.9148 24.4978C30.5728 24.4978 28.5823 23.6603 26.9428 21.9854C25.325 20.3105 24.5161 18.2499 24.5161 15.8036C24.5161 13.3573 25.325 11.2967 26.9428 9.62178C28.5823 7.94684 30.5728 7.10938 32.9148 7.10938C35.2565 7.10938 37.2471 7.94684 38.8865 9.62178C40.5259 11.2967 41.3454 13.3573 41.3454 15.8036C41.3454 18.2499 40.5259 20.3105 38.8865 21.9854ZM32.9148 21.6879C34.5114 21.6879 35.8528 21.1259 36.9384 20.0019C38.0241 18.878 38.5672 17.4785 38.5672 15.8036C38.5672 14.1287 38.0241 12.7292 36.9384 11.6052C35.8528 10.4813 34.5114 9.91931 32.9148 9.91931C31.3392 9.91931 30.0087 10.4813 28.9228 11.6052C27.8371 12.7292 27.2943 14.1287 27.2943 15.8036C27.2943 17.4785 27.8371 18.878 28.9228 20.0019C30.0087 21.1259 31.3392 21.6879 32.9148 21.6879Z"
        fill="#101018"
      />
      <path
        id="Vector_11"
        d="M20.917 0.928101V24.0686H17.9791V5.98593L10.666 18.581H10.283L2.96988 6.019V24.0686H0V0.928101H3.35323L10.4746 13.1595L17.5638 0.928101H20.917Z"
        fill="#101018"
      />
    </g>
  </svg>
);
export default MonoCreatorLogo;
