import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, updateUserData } from "../thunk/user";
import { LOADING_STATUS } from "../../constants";

const initialState = {
  status: null,
  error: null,
  userData: null,
  subscription: null,
  isAuth: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuth: (state, bool) => {
      state.setAuth = bool;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
    },
    lessSitesToGenerate: (state) => {
      state.subscription.current_sites_left =
        state.subscription.current_sites_left - 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });

    builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.userData = payload;
      state.subscription = payload?.subscription;
      return;
    });
    builder.addCase(fetchUserData.rejected, (state) => {
      state.status = LOADING_STATUS.REJECTED;
    });
    builder.addCase(updateUserData.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });

    builder.addCase(updateUserData.fulfilled, (state, { payload }) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.userData = payload;
      return;
    });
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;

// в слайсе есть и экшены и редьюсеры - удобная форма создания всего и сразу
