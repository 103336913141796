import { Button, GrayButton } from './styled';
import LogOutIcon from '../../../../assets/icons/log-out';
import SettingsIcon from '../../../../assets/icons/settings';
import UsersLeftIcon from '../../../../assets/icons/users-left';
import { useNavigate } from 'react-router-dom';
import TooltipModal from '../../../../ui/TooltipModal/TooltipModal';

const LogoutModal = ({logout}) => {
  const navigate = useNavigate()

 

  const goToSetting = ()=> navigate('/home/settings')

  return (
    <TooltipModal sx={{top: '60px', display: 'flex', flexDirection: 'column', gap:'6px'}}>
      <GrayButton>
        <UsersLeftIcon/>
        Сменить пользователя
      </GrayButton>
      <Button onClick={goToSetting}>
        <SettingsIcon width={16} height={16}/>
        Настройки
      </Button>
      <Button onClick={logout}>
        <LogOutIcon/>
        Выйти
      </Button>
    </TooltipModal>
  )
}

export default LogoutModal;
