import React from 'react'
import userAvatar from '../../assets/images/userAvatar.png';
import { UserAvatar } from './styled';

const ImageAvatar = ({avatarFile, width, height}) => {
  return (
    <>
      {
        avatarFile ? 
          <UserAvatar 
            src={`data:image/jpeg;base64,${avatarFile}`} 
            alt="user-avatar" 
            width={width} 
            height={height}
          /> 
        : 
          <UserAvatar 
            src={userAvatar} 
            width={width} 
            height={height}
          />
      }
    </>
  )
}

export default ImageAvatar;
