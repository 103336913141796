import { combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "./slices/user";
import { sitesReducer } from "./slices/sites";
import { subscriptionReducer } from "./slices/subscription";
import { notificationsReducer } from "./slices/notifications";

const appReducer = combineReducers({
  user: userReducer,
  sites: sitesReducer,
  subscription: subscriptionReducer,
  notifications: notificationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
