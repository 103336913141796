import React, { useEffect, useState } from "react";
import { Body, Container, ContentContainer } from "./styled";
import { Outlet, useNavigate } from "react-router-dom";
import { LOADING_STATUS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../redux/thunk/user";
import { Header } from "../../modules/Header/index";
import Sidebar from "../../modules/Sidebar/Sidebar";
import Preloader from "../../ui/Preloader/Preloader";

const PageLayout = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  useEffect(() => {
    const paths = window.location.pathname.split("/");
    setSelectedSection(paths[paths.length - 1]);
  }, [window.location.pathname]);

  const { userData, status, subscription } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/");
    } else {
      setPreloader(false);
      if (!userData) {
        dispatch(fetchUserData());
      }
    }
  }, []);

  const outletContext = [userData];
  if (status === LOADING_STATUS.LOADING || preloader) {
    return <Preloader />;
  }
  return (
    <Container>
      <Sidebar
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
      />
      <Body>
        <Header
          userData={userData}
          subscription={subscription}
          setSelectedSection={setSelectedSection}
          selectedSection={selectedSection}
        />
        <ContentContainer>
          <Outlet context={outletContext} />
        </ContentContainer>
      </Body>
    </Container>
  );
};

export default PageLayout;
