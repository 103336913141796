import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
// todo навести порядок в общих стилях кнопок и ссылок, привести к одному шаблону
export const SButton = styled.button`
  background-color: inherit;
  border: none;
  padding: ${({ padding }) => padding ?? "16px 24px"};
  border-radius: 12px;

  &:hover {
    background-color: var(--color-gray-100);
  }
  &:disabled {
    opacity: 0.7;
  }
`;

export const GreenButton = styled.button`
  border: none;
  border-radius: ${({ br }) => br ?? "12px"};
  background-color: var(--color-brand-green);
  padding: ${({ padding }) => padding ?? "16px 24px"};
  height: 100%;
  color: white;
  text-decoration: none;

  & path {
    stroke: white;
  }

  &:hover {
    color: white;
    background-color: var(--color-brand-green-600);
  }

  &:disabled,
  &:disabled:hover {
    background-color: var(--color-gray-300);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export const SWhiteTemplate = styled.div`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid var(--color-gray-300);
  box-shadow: var(--box-shadow-pop-up);
`;
export const SWhiteBackground = styled.div`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid var(--color-gray-200);
  padding: ${({ padding }) => padding ?? "12px"};
`;

const SGreyButton = css`
  background-color: var(--color-gray-100);
  border: none;
  border-radius: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--color-brand-black);
  text-decoration: none;
  font-weight: 500;
  & path {
    stroke: var(--color-brand-black);
  }
  &:hover {
    color: var(--color-brand-black);
    background-color: var(--color-gray-200);
  }

  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background-color: var(--color-gray-100);
  }
`;
export const SGrayButton = styled.button`
  ${SGreyButton}
`;
export const SGrayLinkBtn = styled.a`
  ${SGreyButton}
  &:hover {
    color: var(--color-brand-black);
    background-color: var(--color-gray-200);
  }
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};

  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;
export const SRedBtn = styled.button`
  ${SGreyButton}
  color: white;
  background-color: var(--color-error-400);
  & path {
    stroke: white;
  }
  &:hover {
    color: white;
    background-color: var(--color-error-600);
  }
  &:disabled {
    background-color: var(--color-error-400);
  }
`;

export const SButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;

  & > button {
    width: ${({ bw100 }) => (bw100 ? "100%" : "auto")};
  }
`;

const buttonType = {
  green: {
    "background-color": "var(--color-brand-green)",
    border: "1px solid transparent",
    color: "white",
    hover: "var(--color-brand-green-600)",
  },
  black: {
    "background-color": "var(--color-brand-black)",
    border: "1px solid transparent",
    color: "white",
    hover: "var(--color-gray-800)",
  },
  white: {
    "background-color": "white",
    border: "1px solid var(--color-gray-300)",
    color: "var(--color-brand-black)",
    hover: "var(--color-gray-100)",
  },
  gray: {
    "background-color": "var(--color-gray-100)",
    border: "1px solid transparent",
    color: "var(--color-brand-black)",
    hover: "var(--color-gray-200)",
  },
  "light-gray": {
    "background-color": "var(--color-gray-50)",
    border: "1px solid transparent",
    color: "var(--color-brand-black)",
    hover: "var(--color-gray-200)",
    smallSizesBgc: "var(--color-gray-100)",
  },
};

const ButtonStyles = css`
  border-radius: ${({ br }) => br ?? "12px"};
  display: flex;
  align-items: ${({ align }) => align ?? "center"};
  justify-content: ${({ justify }) => justify ?? "center"};
  gap: 8px;
  font-weight: ${({ fw }) => fw ?? "normal"};
  &:disabled {
    opacity: 0.6;
  }

  padding: ${({ padding }) => padding ?? "12px 16px"};
  background-color: ${({ color, isActive }) =>
    isActive ? buttonType[color].hover : buttonType[color]["background-color"]};
  border: ${({ color, border }) => border ?? buttonType[color].border};
  border-bottom: ${({ color, borderBottom }) =>
    borderBottom && buttonType[color]["border"]};
  color: ${({ color }) => buttonType[color].color};

  &:hover {
    background-color: ${({ color, hover }) =>
      hover ? buttonType[hover]["background-color"] : buttonType[color].hover};

    color: ${({ color, hover }) => buttonType[hover ?? color].color};

    border: ${({ color, hover, border }) =>
      border ?? buttonType[hover ?? color].border};
  }
  &:hover path {
    stroke: ${({ color, hover }) => buttonType[hover ?? color].color};
  }

  &:disabled:hover {
    background-color: ${({ color }) => buttonType[color]["background-color"]};
  }

  & path {
    stroke: ${({ color }) => buttonType[color].color};
  }
`;
export const StylesButton = styled.button`
  ${ButtonStyles};

  @media (max-width: 768px) {
    background-color: ${({ color }) =>
      buttonType[color].smallSizesBgc ?? buttonType[color]["background-color"]};
  }
`;
export const StyledLink = styled.a`
  text-decoration: none;
  ${ButtonStyles};
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  ${ButtonStyles};
`;

export const SCheckbox = styled.input`
  accent-color: var(--color-brand-green);
`;

export const SInput = styled.input`
  border: 1px solid var(--color-gray-200);
  border-radius: 12px;
  padding: 12px 16px;
  background-color: var(--color-gray-50);
  display: block;
  width: 100%;
  /* flex-grow: 0; */
`;

{
  /* Separator */
}
export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid var(--color-gray-100);
`;

export const ModalContainer = styled.div`
  padding: 24px;
  background-color: #fff;
  border: 1px solid var(--color-gray-100);
  box-shadow: var(--box-shadow-background);
  border-radius: 12px;
`;

export const SErrorText = styled.span`
  color: var(--color-error-600);
  align-self: ${({ align }) => align ?? "inherit"};
  max-width: ${({ maxWidth }) => maxWidth ?? "auto"};
`;
