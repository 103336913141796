import styled from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  border: 1px solid var(--color-gray-100);
  color: var(--color-gray-500);
  /* min-width: 20vw; */
  box-shadow: var(--box-shadow-pop-up);
  ${({ sx }) => sx};
  
`
export const TriangleContainer = styled.div`
  position: absolute; 
  top: -16.4px; 
  right: ${({right}) => right && '5px'};
  left: ${({left}) => left && '5px'};
`