import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  /* width: 100vw; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-100);
`
export const GreenNavLink = styled(NavLink)`
  text-decoration: none;
  text-align: center;
  color: white;
  display: block;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: var(--color-brand-green);
  border: none;
  height: 100%;

  &:hover { 
    color: white;
  }
`
export const GreenLink = styled(NavLink)`
  color: var( --color-brand-green);

  &:hover { 
    color: var( --color-brand-green);
  }
  @media(max-width: 430px) {
    font-size: 14px;
  }
`