import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationsApi from "../../api/NotificationsApi";

export const fetchNotificationsList = createAsyncThunk(
  'notification/fetchNotificationsList',
  async() => {
    try {
      const data = await NotificationsApi.fetchNotificationsList()
      return data;
    }
    catch(e) {
      console.log(e)
      throw e;
    }
  }
)


export const fetchArchivedNotifications = createAsyncThunk(
  'notification/fetchArchivedNotifications',
  async() => {
    try {
      const data = await NotificationsApi.fetchNotificationsByStatus('archived')
      return data;
    }
    catch(e) {
      console.log(e)
      throw e;
    }
  }
)