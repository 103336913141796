import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { SSiteBtn } from "../../../Sidebar/styled";

export const SSidebarContainer = styled.div`
  width: 100%;
  background-color: #a8a8a895;
  position: absolute;
  top: 12vh;
  left: 0;
  z-index: 20;
  height: 100%;
  max-height: 88vh;
`;
export const SSidebar = styled.div`
  opacity: 1;
  background-color: white;
  max-height: 88vh;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1160px) {
    max-width: 50vw;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 8px; // ширина для вертикального скролла
    background-color: var(--color-gray-100);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-300);
    border-radius: 12px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;

  flex-direction: column;
  gap: 24px;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SSidebarBtn = css`
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;

  font-weight: 500;
`;
export const LogoutBtn = styled.button`
  ${SSidebarBtn};
  border: none;
  width: 100%;

  color: var(--color-gray-500);
  background-color: #fff;
  margin-top: auto;
  &:hover {
    background-color: var(--color-gray-100);
  }
`;
export const MenuItem = styled(NavLink)`
  ${SSidebarBtn};
  border: 1px solid var(--color-gray-200);
  box-shadow: var(--box-shadow-background);
  color: ${({ selected }) => (selected ? "white" : "var(--color-brand-black)")};
  text-decoration: none;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "cursor")};

  background-color: ${({ selected, isDisabled }) =>
    selected
      ? "var(--color-brand-green)"
      : isDisabled
      ? "var(--color-gray-300)"
      : "white"};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.2" : "1")};

  &:hover {
    color: ${({ selected }) =>
      selected ? "white" : "var(--color-brand-black)"};
    background-color: ${({ selected, isDisabled }) =>
      selected
        ? "var(--color-brand-green)"
        : isDisabled
        ? "var(--color-gray-300)"
        : "var(--color-gray-100)"};
  }
  & path {
    stroke: ${({ selected }) => (selected ? "white" : "#667085")};
  }
  border-radius: ${({ rightBr }) => (rightBr ? "12px 12px 0 0" : "12px")};
`;
export const Module = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Divider = styled.div`
  width: 100%;
  background: var(--color-light-gray);
  height: 1px;
`;
//todo вынести в отдельные стили
export const BalanceBgc = styled.div`
  display: flex;
  /* height: 100%; */
  align-items: center;
  gap: 4px;
  padding: 4px;
  padding-right: 10px;

  color: white;
  font-weight: bold;
  border-radius: 24px;
  background: linear-gradient(90deg, #01c38e 15.66%, #0194c3 100%);

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const SSiteMenu = styled(SSiteBtn)`
  display: block;
  border: 1px solid var(--color-gray-100);
  border-top: none;

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;
