import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: hidden;

  background-color: #f3f4f6;
`;

export const Body = styled.div`
  box-sizing: border-box;
  background-color: #f2f5f9;
  width: 100%;
  height: 100vh;

  @media (max-width: 768px) {
    background-color: var(--color-gray-50);
  }

  @media (max-width: 430px) {
    background-color: #fff;
  }
`;
export const ContentContainer = styled.div`
  padding: 0px 2vw;
  padding-bottom: 48px;
  overflow-y: auto;
  height: 88vh;

  @media (max-width: 430px) {
    padding: 0 15px;
    padding-bottom: 48px;
  }
`;
