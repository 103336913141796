import http from "./http";

export default class NotificationsApi {
  static async fetchNotificationsList() {
    const { data } = await http.get(`notifications/user`);
    return data;
  }

  static async fetchNotificationsByStatus(status = "unread") {
    const { data } = await http.get(`notifications/user/${status}`);
    return data;
  }

  static async sendSubExpiredNotification(userId) {
    const { data } = await http.post(
      `notifications/user/${userId}/send/sub_expires`
    );
    return data;
  }
  static async senWelcomeNotification(userId) {
    const { data } = await http.post(
      `notifications/user/${userId}/send/welcome`
    );
    return data;
  }
  static async sendSubApplied(userId) {
    const { data } = await http.post(
      `notifications/user/${userId}/send/sub_applied`
    );
    return data;
  }
  static async sendSiteAccessApplied(userId) {
    const { data } = await http.post(
      `notifications/user/${userId}/send/access_applies`
    );
    return data;
  }
  static async sendSiteAccessRevoked(userId) {
    const { data } = await http.post(
      `notifications/user/${userId}/send/access_revoked`
    );
    return data;
  }
  static async markNotificationAsRead(notificationId) {
    const { data } = await http.post(
      `notifications/${notificationId}/mark_as_read`
    );
    return data;
  }
  static async markNotificationAsUnRead(notificationId) {
    const { data } = await http.post(
      `notifications/${notificationId}/mark_as_unread`
    );
    return data;
  }
  static async archiveNotification(notificationId) {
    const { data } = await http.post(
      `notifications/${notificationId}/mark_as_archived`
    );
    return data;
  }
  static async recoverNotification(notificationId) {
    const { data } = await http.post(
      `notifications/${notificationId}/mark_as_unarchived`
    );
    return data;
  }
  static async readAll() {
    const { data } = await http.post(
      `notifications/user/read_all`
    );
    return data;
  }
}
