import styled from "styled-components";
import { SButton } from "../../../../components/styledCommon";

export const SHeader = styled.header`
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 16px 0;
  border-bottom: 1px solid var(--color-light-gray);
  background-color: var(--color-white);

  @media(max-width: 1160px) {
    padding: 0 15px;
    justify-content: space-between;
  }
`
export const Container = styled.div`
  padding: 0 24px;
  display: ${({isDesktopHidden}) => isDesktopHidden ?  'none' : 'flex'};
  align-items: center;
  justify-content: center;
  gap: ${({gap}) => gap ?? '12px'};
  flex-grow: ${({flexGrow})=> flexGrow ?? 0};

  @media(max-width: 1160px) {
    display: ${({isMobileHidden, clicked}) => isMobileHidden || clicked ?  'none' : 'flex'};
    padding: 0px;
    justify-content: end;
  }
`

export const ButtonContainer = styled(SButton)`
  display: none;

  @media(max-width: 1160px) {
    display: block;
  }
`

export const BurgerMenuBtn = styled(ButtonContainer)`
  display: none;

  @media(max-width: 1160px) {
    display: block;
  }
`
export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  @media(max-width: 1160px) {
    display: none;
  }

`

export const Search = styled.input`
  width: 100%;
  background-color: inherit;
  border: none;
  &:focus{
  outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`

export const SearchContainer = styled.div`
  width: 100%;
  background-color: var(--color-gray-50);
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--color-gray-100);
  
  
`
export const SearchBtn = styled(ButtonContainer)`
  &:focus {
    outline: none;
  }
  display: none;

  @media (max-width: 1160px) {
    display: block;
  }
`
export const Divider = styled.div`
  height: 100%;
  background: var(--color-light-gray);
  width: 1px;

  @media (max-width: 1160px) {
    display: none;
  }
`
export const Term = styled.span`
  padding: 0 12px;
  background-color: var(--color-brand-green);
  border-radius: 12px;
  color: white;
`

export const TariffContainer = styled.div`
  & > h6 {
    font-weight: normal;
    color: var(--color-gray-400);
    font-size: 14px;
  }
`
export const STariffText = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: var(--color-gray-400);
`

export const LogosContainer = styled.div`
  display: none;

  @media(max-width: 1160px) { 
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
  }
`
export const Balance = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 4px;
  padding: 4px;
  padding-right: 10px;

  color: white;
  font-weight: bold;
  border-radius: 24px;
  background: linear-gradient(90deg, #01C38E 15.66%, #0194C3 100%);

  @media(max-width: 1160px) {
    display: none;
  }
`