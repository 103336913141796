import React from 'react'
import { ModalContainer, TriangleContainer } from './styled'
import Triangle from '../../assets/icons/triangle'

const TooltipModal = ({children, sx}) => {
  return (
    <ModalContainer 
      sx={sx} 
      className='customTooltip'
    >
      <TriangleContainer left={sx.left} right={sx.right}>
        <Triangle />
      </TriangleContainer>
      {children}
    </ModalContainer>
  )
}

export default TooltipModal
