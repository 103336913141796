import { createAsyncThunk } from "@reduxjs/toolkit";
import UserApi from "../../api/UserApi";

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async() => {
    try {
      const data = await UserApi.fetchUser()
      console.log(data)

      return data;

    }
    catch(e) { 
      console.log(e)
      throw e;
    }
  }
)

export const updateUserData = createAsyncThunk(
  'user/updateUserData',
  async(updateUserData) => {
    try {
      const data = await UserApi.updateUser(updateUserData)
      return data;
    }
    catch(e) {
      console.log(e)
      throw e;
    }
  }
)

export const fetchUserSites = createAsyncThunk(
  'user/fetchUserSites',
  async() => {
    try {
      const data = await UserApi.getUserSites()
      return data;
    }
    catch(e) {
      console.log(e)
      throw e;
    }
  }
)
