import styled from "styled-components";
import { css } from "styled-components";
import ArrowRightIcon from "../../assets/icons/arrow-right";
import { Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";

export const lightTheme = {
  bg: "#E5E7EB",
  bgAlpha: "rgba(250,250,250,.3)",
  bg2: "rgb(245,245,245)",
  bg3: "rgb(230,230,230)",
  text: "rgb(45,45,45)",
  primary: "rgb(52, 131, 235)",
};

export const v = {
  sidebarWidth: `270px`,
  smSpacing: `8px`,
  mdSpacing: `16px`,
  lgSpacing: `24px`,
  xlSpacing: `32px`,
  xxlSpacing: `48px`,
  borderRadius: `6px`,
};

export const btnReset = css`
  font-family: inherit;
  outline: none;
  border: none;
  background: none;
  letter-spacing: inherit;
  color: inherit;
  font-size: inherit;
  text-align: inherit;
  padding: 0;
`;

export const SSidebar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ isOpen }) => (!isOpen ? `100px` : v.sidebarWidth)};
  height: 100vh;

  background: ${({ theme }) => theme.bg};
  border-right: 1px solid #e6e6e6;

  transition: all 0.5s;
  /* padding: ${v.lgSpacing}; */

  color: #0c0d0f;

  @media (max-width: 1160px) {
    //1160px // 1250
    display: none;
  }
`;

export const SSidebarButton = styled(ArrowRightIcon)`
  ${btnReset};

  position: absolute;
  z-index: 11;
  top: 4vh;
  right: ${({ isOpen }) => (isOpen ? `-16px` : `-15px`)};
  width: 25px;
  height: 25px;

  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;

  rotate: 180deg;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transform: ${({ isOpen }) => (!isOpen ? `rotate(180deg)` : `initial`)};
`;

export const SLogo = styled(NavLink)`
  position: relative;
  padding: ${v.lgSpacing};
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 12vh;
  flex-shrink: 0;
  cursor: pointer;

  > svg {
    transition: all 1s;

    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  }
  > div {
    transition: margin-left 0.5s;
    margin-left: ${({ isActive }) => (isActive ? "0" : "12px")};
  }
`;

export const SDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.bg3};
  margin: ${v.lgSpacing} 0;
`;

export const SLinkLabel = styled.span`
  flex: 1;
  font-weight: 600;
`;

export const SLinkContainer = styled(Tooltip)`
  background: ${({ theme, isActive }) =>
    !isActive ? `transparent` : theme.bg3};

  border-radius: ${({ isActive }) => (isActive ? "0px" : `${v.borderRadius}`)};

  display: flex;
  align-items: center;
  min-height: 56px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    /* box-shadow: inset 0 0 0 1px ${({ theme }) => theme.bg3};/ */
    /* background-color: #efefef; */
  }
`;

export const SOpenListBtn = styled.button`
  border: none;
  background-color: transparent;
`;
export const SLinkContainerAnim = styled(SLinkContainer)`
  overflow: hidden;

  transition: padding 0.5s;
  padding: calc(${v.smSpacing} - 2px)
    ${({ isActive }) => (isActive ? "24px" : "0")};

  ${SLinkLabel}, ${SOpenListBtn} {
    transition: visibility 0.8s, opacity 0.8s, transform 0.8s;

    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  }
  ${SLinkLabel} {
    white-space: ${({ textLength }) => (textLength < 22 ? "pre" : "normal")};
    /* transform: translateX(${({ isActive }) =>
      isActive ? "0px" : "-50px"}); */
  }
  background-color: ${({ selected, isDisabled }) =>
    selected
      ? "var(--color-brand-green)"
      : isDisabled
      ? "var(--color-gray-200)"
      : "inherit"};
  color: ${({ selected }) => (selected ? "white" : "inherit")} !important;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.2" : "1")};
  &:hover {
    background-color: ${({ selected }) => (selected ? "none" : "#efefef")};
    color: ${({ selected }) => (selected ? "white" : "inherit")};
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  }
  & svg,
  path {
    stroke: ${({ selected }) => (selected ? "white" : "#667085")};
  }
`;

export const SLinkIcon = styled.div`
  svg {
    margin: 16px;
    font-size: 20px;
  }
  img {
    margin: 8px;
    margin-right: 15px;
    height: 40px;
    display: block;
    width: 40px;
    max-width: none;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: ${({ isActive }) => (isActive ? "0px" : " 12px 22px")};
  transition: padding 1s;
  overflow: auto;
  min-height: 88vh;

  &::-webkit-scrollbar {
    width: 8px; // ширина для вертикального скролла
    background-color: var(--color-gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-300);
    border-radius: 12px;
  }
`;

export const SidebarSelect = styled.div``;
export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
export const CustomWrapper = styled.div``;
export const ProfileImage = styled.img``;

export const SSiteBtn = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: ${({ selected }) => (selected ? "white" : "var(--color-brand-black)")};
  padding: 12px 48px;
  width: 100%;
  background-color: ${({ selected }) =>
    selected ? "var(--color-brand-green)" : "white"};
  text-align: start;
  &:hover {
    background-color: ${({ selected }) =>
      selected ? "var(--color-brand-green)" : "var(--color-gray-100)"};
    color: ${({ selected }) =>
      selected ? "white" : "var(--color-brand-black)"};
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SSitesList = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  box-shadow: var(--box-shadow-background);

  &::-webkit-scrollbar {
    display: none;
  }
`;
