import {createSlice} from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../constants';
import { fetchPlansSubscription } from '../thunk/subscription';

const initialState = {
  status: null,
  error: null,
  plans: null,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState, 
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlansSubscription.fulfilled, (state, {payload}) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.plans = payload;
      return;
    })
    builder.addCase(fetchPlansSubscription.rejected, (state) => {
      state.status = LOADING_STATUS.REJECTED;
    })
    builder.addCase(fetchPlansSubscription.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    })
  }
})

export const subscriptionReducer = subscriptionSlice.reducer;
export const subscriptionActions = subscriptionSlice.actions; 