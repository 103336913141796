const AsideLogo = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="44" 
    height="45" 
    viewBox="0 0 44 45" 
    fill="none"
    {...props}
  >
  <path 
    fillRule="evenodd" 
    clipRule="evenodd" 
    d="M41.9375 22.5C41.9375 33.5112 33.0112 42.4375 22 42.4375C21.2773 42.4375 20.5635 42.399 19.8606 42.3241C21.0029 41.6305 22.0304 40.7812 22.9097 39.7908C25.4946 36.8796 26.5866 32.9879 25.9456 28.9715C25.3046 24.9553 22.9833 21.1435 19.4922 18.375L9.74607 29.352L5.67063 33.9422C3.39704 30.7035 2.0625 26.7576 2.0625 22.5C2.0625 11.4888 10.9888 2.5625 22 2.5625C33.0112 2.5625 41.9375 11.4888 41.9375 22.5ZM4.26693 35.5232C1.5847 31.877 0 27.3736 0 22.5C0 10.3497 9.84974 0.5 22 0.5C34.1503 0.5 44 10.3497 44 22.5C44 34.6503 34.1503 44.5 22 44.5C20.1056 44.5 18.2671 44.2606 16.5131 43.8103C15.1415 44.2259 13.6789 44.4596 12.1626 44.4952C7.86621 44.5962 3.49119 43.0975 0 40.329L4.26693 35.5232Z" 
    fill="#0EBC5A"
  />
  </svg>
)

export default AsideLogo
