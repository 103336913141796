import styled from "styled-components"
import { SButton } from "../../../../components/styledCommon"

export const Button = styled(SButton)`
  background-color: #fff;
  border: none;
  min-width: 220px;
  display: flex;
  gap: 8px;
  text-align: start;
  align-items: center;
  color: inherit;
  padding: 6px;
  border-radius: 0;
  
  @media(max-width: 430px) {
    min-width: 87vw;
  }
`
export const GrayButton = styled(Button)`
  color: var(--color-gray-300);
  background-color: var(--color-gray-100);
`

