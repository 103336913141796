import { createAsyncThunk } from "@reduxjs/toolkit";
import SubscriptionApi from "../../api/SubscriptionApi";

export const fetchPlansSubscription = createAsyncThunk(
  'subscription/fetchPlanSubscription',
  async() => {
    try {
      const data = await SubscriptionApi.fetchPlans()
      return data;
    }
    catch(e) {
      console.log(e)
      throw e;
    }
  }
)