import http from "./http";

export default class SubscriptionApi {
  static async fetchPlans() {
    const {data} = await http.get('subscriptions/plans')
    return data;
  }
  static async applySubscriptionPlans(planId) {
    const {data} = await http.post(`subscriptions/apply_plan/${planId}`)
    return data;
  }
  static async CheckPaymentStatus() {
    const {data} = await http.get('subscriptions/apply_plan/check_status')
    return data;
  }
}
