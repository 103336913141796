import http from "./http";

export default class UserApi {
  static async fetchUser() {
    const {data} = await http.get('users/me')
    return data;
  }
  static async getUserSites() {
    const {data} = await http.get('users/me/sites')
    return data;
  }
  static async updateUser(updatedUserData) {
    const {data} = await http.patch('users/me',
      updatedUserData 
    )
    return data;
  }
  static async getReferral() {
    const {data} = await http.get('users/me/referral_token')
    return data;
  }
}