import React, { useCallback, useEffect, useState } from "react";
import {
  Balance,
  BurgerMenuBtn,
  Container,
  Divider,
  ImageContainer,
  LogosContainer,
  SHeader,
  STariffText,
  Search,
  SearchContainer,
  TariffContainer,
} from "./styled";
import SearchIcon from "../../../../assets/icons/search-md";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenuIcon from "../../../../assets/icons/burger-menu";
import LogoutModal from "../LogoutModal/LogoutModal";
import SymbolIcon from "../../../../assets/icons/symbol";
import MonoCreatorLogo from "../../../../assets/icons/mono-creator-logo";
import AsideLogo from "../../../../assets/icons/aside-logo";
import Cross from "../../../../assets/icons/cross";
import moment from "moment";
import { HOME_URL, TARIFF_PLANS_TITLE } from "../../../../constants";
import NotificationsBell from "../NotificationsBell/NotificationsBell";
import ImageAvatar from "../../../../ui/ImageAvatar/ImageAvatar";
import { GreenButton } from "../../../../components/styledCommon";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import InfoPopup from "../../../../components/InfoPopup/InfoPopup";
import { useDispatch, useSelector } from "react-redux";
import { sitesActions } from "../../../../redux/slices/sites";
import { userActions } from "../../../../redux/slices/user";

export const Header = ({
  userData,
  subscription,
  setSelectedSection,
  selectedSection,
}) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { searchText } = useSelector((state) => state.sites);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const closeLogoutModal = (e) => {
      if (e.target.parentNode?.id !== "avatar") {
        setIsLogoutModalOpen(false);
      }
    };
    window.addEventListener("click", closeLogoutModal);

    return () => window.removeEventListener("click", closeLogoutModal);
  }, []);

  const navigate = useNavigate();
  const onImprove = () => navigate("/home/payment");

  const logout = useCallback(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch(userActions.logout());

    navigate("/");
  }, []);

  const onSearchChange = (e) =>
    dispatch(sitesActions.setSearchText(e.target.value));

  const sitesLeft =
    subscription?.current_sites_left === -1
      ? "Безлимитно"
      : `${subscription?.current_sites_left}`;
  return (
    <SHeader>
      {isMenuOpened && (
        <SidebarMenu
          onClose={() => setIsMenuOpened(false)}
          onImprove={onImprove}
          setSelectedSection={setSelectedSection}
          selectedSection={selectedSection}
          userData={userData}
          subscription={subscription}
          logout={logout}
        />
      )}
      <Container
        flexGrow="0.2"
        clicked={searchClicked}
        onClick={() => setIsMenuOpened((prev) => !prev)}
        isDesktopHidden
        className="justify-content-start"
      >
        <BurgerMenuBtn padding="12px 14px">
          {isMenuOpened ? <Cross /> : <BurgerMenuIcon />}
        </BurgerMenuBtn>
      </Container>

      <Container flexGrow="2" clicked={!searchClicked}>
        <SearchContainer>
          <SearchIcon />
          <Search
            disabled={pathname !== HOME_URL}
            type="text"
            value={searchText}
            onChange={onSearchChange}
            placeholder="Поиск"
          />
        </SearchContainer>
      </Container>

      <Divider />

      <Container gap="24px" isMobileHidden>
        <TariffContainer>
          <h6 className="m-0">Текущий тариф:</h6>
          <div className="d-flex align-items-center gap-2">
            <p className="m-0 fw-bold">
              {TARIFF_PLANS_TITLE[subscription.plan_id]}
            </p>

            <InfoPopup sx={{minWidth: '16.5vw'}}>
              <div>
                <STariffText>Действует до </STariffText>
                <span className="fw-bold">
                  {moment(Date.parse(subscription.ends_at)).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </span>

                <STariffText className="pt-1">Доступно сайтов для создания:</STariffText>
                <span className="fw-bold">{sitesLeft}</span>
              </div>
            </InfoPopup>
          </div>
        </TariffContainer>
        <GreenButton onClick={onImprove} padding="12px 16px">
          Улучшить
        </GreenButton>
      </Container>

      <Divider />

      <LogosContainer>
        <AsideLogo height="24" width="24" />
        <MonoCreatorLogo height="cover" width="112" />
      </LogosContainer>

      <Container>
        <Balance>
          <SymbolIcon />
          <span>{subscription?.current_tokens_quantity ?? 500}</span>
        </Balance>

        <NotificationsBell />

        <ImageContainer
          id="avatar"
          onClick={() => setIsLogoutModalOpen((prev) => !prev)}
        >
          <ImageAvatar avatarFile={userData?.avatar_filename} />
          {isLogoutModalOpen && <LogoutModal logout={logout} />}
        </ImageContainer>
      </Container>
    </SHeader>
  );
};
