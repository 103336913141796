const ENV = process.env.REACT_APP_ENV || 'prod';

const SERVER_URL_PROD = process.env.REACT_APP_MONO_SERVER_PROD;
const SERVER_URL_DEV = process.env.REACT_APP_MONO_SERVER_DEV;

const AI_SERVER_URL_PROD = process.env.REACT_APP_AI_SERVER_PROD;
const AI_SERVER_URL_DEV = process.env.REACT_APP_AI_SERVER_DEV;

export const URLS = {
  SERVER: ENV === 'prod' ? SERVER_URL_PROD : SERVER_URL_DEV,
  AI: ENV === 'prod' ? AI_SERVER_URL_PROD : AI_SERVER_URL_DEV
}