import React, {  useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Bell from '../../../../assets/icons/bell';
import BellNewNotifications from '../../../../assets/icons/bell-new-notifications';

import NotificationsApi from '../../../../api/NotificationsApi';
import { SButton } from '../../../../components/styledCommon';

const NotificationsBell = () => {
  const [isNewNotifications, setIsNewNotifications] = useState(false)
  const {allNotifications} = useSelector(state => state.notifications)

  useEffect(()=> {
    if(allNotifications?.length) {
      const isNewNotifications = allNotifications.some(n => !n.is_read)

      isNewNotifications ? setIsNewNotifications(true) : setIsNewNotifications(false)
    }
  }, [allNotifications])

  
  useEffect(()=> {
    const getUnreadNotifications = async ()=> {
      const notifications = await NotificationsApi.fetchNotificationsByStatus('unread')
      if(notifications.length) {
        setIsNewNotifications(true)
      }
    }
    getUnreadNotifications()

    const id = setInterval(getUnreadNotifications, 60000)

    return ()=> clearInterval(id)
  }, [])

  const navigate = useNavigate()
  const handleClick = ()=> navigate('notifications')

  return (
    <SButton padding='10px 12px' onClick={handleClick}>
      { isNewNotifications ? <BellNewNotifications/> : <Bell/> }
    </SButton>
  )
}

export default NotificationsBell
