import { Route, Routes } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import {GenerationSteps} from "./pages/generation/GenerationSteps/GenerationSteps.lazy";
import PageLayout from "./layouts/PageLayout/PageLayout";
import {MobileBuilderBackground} from "./pages/content/MobileBuilderBackground/MobileBuilderBackground.lazy";
import {GeneratedSite} from "./pages/generation/GeneratedSite/GeneratedSite.lazy";
import { CHILDREN_ROUTES, ROUTE_LAYOUTS } from "./routerConfig/constants";
import { Loading, getRoutePages } from "./routerConfig/utils";

function App() {
  return (
    <div className="content" id="content">
      <Routes>
        <Route
          path="/"
          element={
            <Loading>
              <AuthLayout />
            </Loading>
          }
        >
          {getRoutePages(CHILDREN_ROUTES[ROUTE_LAYOUTS.AUTH])}
        </Route>
        <Route
          path="/home"
          element={
            <Loading>
              <PageLayout />
            </Loading>
          }
        >
          {getRoutePages(CHILDREN_ROUTES[ROUTE_LAYOUTS.CONTENT], true)}
        </Route>
        <Route
          path="/create/ai/*"
          element={
            <Loading>
              <GenerationSteps />
            </Loading>
          }
        />
        <Route
          path="/create/ai/generated-site"
          element={
            <Loading>
              <GeneratedSite />
            </Loading>
          }
        />
        <Route
          path="/mobile-background/:id"
          element={
            <Loading>
              <MobileBuilderBackground />
            </Loading>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
