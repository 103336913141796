import { Suspense } from "react";
import Preloader from "../ui/Preloader/Preloader";
import { Route } from "react-router-dom";

export const Loading = ({ children, isContentPreloader }) => (
  <Suspense fallback={<Preloader isContentPreloader={isContentPreloader} />}>
    {children}
  </Suspense>
);

export const getRoutePages = (pages, isContentPreloader) =>
  pages.map(({ path, element }) => (
    <Route
      key={path}
      path={path}
      element={
        <Loading isContentPreloader={isContentPreloader} key={path}>
          {element}
        </Loading>
      }
    />
  ));