import {
  BalanceBgc,
  LogoutBtn,
  MenuItem,
  Module,
  SSidebar,
  SSidebarContainer,
  SSiteMenu
} from "./styled";
import { Container } from "./styled";
import { useEffect, useState } from "react";
import Symbol from "../../../../assets/icons/symbol";
import ImageAvatar from "../../../../ui/ImageAvatar/ImageAvatar";
import { SIDEBAR_LINKS } from "../../../../constants";
import LogOut from "../../../../assets/icons/log-out";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserSites } from "../../../../redux/thunk/user";
import { SOpenListBtn, SSitesList } from "../../../Sidebar/styled";
import ChevronUp from "../../../../assets/icons/chevron-up";
import ChevronDown from "../../../../assets/icons/chevron-down";
import { useParams } from "react-router-dom";

const SidebarMenu = ({
  onClose,
  setSelectedSection,
  selectedSection,
  userData,
  subscription,
  logout,
}) => {
  const [search, setSearch] = useState("");

  const { sites } = useSelector((state) => state.sites);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sites) {
      dispatch(fetchUserSites());
    }
  }, []);
  const [sitesListOpen, setSitesListOpen] = useState(false);
  const { id: urlId } = useParams();
  return (
    <SSidebarContainer>
      <SSidebar>
        <Container>
          <Module>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <ImageAvatar avatarFile={userData?.avatar_filename} />
                <div>
                  <p className="m-0">{userData?.name ?? "name"}</p>
                  <p className="m-0">{userData?.email ?? "@email.ru"}</p>
                </div>
              </div>
              <BalanceBgc>
                <Symbol />
                <span>{subscription?.current_tokens_quantity ?? 500}</span>
              </BalanceBgc>
            </div>
            <div>
              <p className="m-0">
                Тариф: <span className="fw-bold"> Старт</span>
              </p>
            </div>
            <div>
              <p className="m-0">
                Действует до: <span className="fw-bold"> 20.04.2025 </span>
              </p>
            </div>
          </Module>

          {SIDEBAR_LINKS.map((el) =>
            el.hasList ? (
              <div className="d-flex flex-column">
                <MenuItem
                  to={el.link}
                  key={el.id}
                  onClick={(e) => {
                    if (el.isDisabled) {
                      e.preventDefault();
                      return;
                    }
                    onClose();
                    setSelectedSection(el.link);
                  }}
                  selected={el.link.includes(selectedSection)}
                  isDisabled={el.isDisabled}
                  rightBr={sitesListOpen}
                >
                  {el.icon}
                  {el.title}
                  <div className="ms-auto">
                  {sites?.length > 0 && (
                    <SOpenListBtn onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      setSitesListOpen((p) => !p)
                    }}>
                      {sitesListOpen ? <ChevronUp /> : <ChevronDown />}
                    </SOpenListBtn>
                  )}
                  </div>
                </MenuItem>
                <SSitesList>
                  {sitesListOpen &&
                    sites?.map((el) => (
                      <SSiteMenu
                        to={`edit-site/${el.id}`}
                        selected={el.id == urlId}
                        onClick={onClose}
                      >
                        {el.company_name}
                      </SSiteMenu>
                    ))}
                </SSitesList>
              </div>
            ) : (
              <MenuItem
                to={el.link}
                key={el.id}
                onClick={(e) => {
                  if (el.isDisabled) {
                    e.preventDefault();
                    return;
                  }
                  onClose();
                  setSelectedSection(el.link);
                }}
                selected={el.link.includes(selectedSection)}
                isDisabled={el.isDisabled}
              >
                {el.icon}
                {el.title}
              </MenuItem>
            )
          )}
        </Container>
        <LogoutBtn onClick={logout}>
          <LogOut />
          Выйти из аккаунта
        </LogoutBtn>
      </SSidebar>
    </SSidebarContainer>
  );
};

export default SidebarMenu;
