import styled, { css } from "styled-components";

export const SPreloaderWrapper = styled.div`
  ${({isContentPreloader}) => 
    isContentPreloader ? 
      css`
        padding: ${({top}) => top ?? '20vh'} 0;
      `
    :
      css`
        height: 100vh;
        width: 100vw; 
        padding-top: 40vh;
      ` 
    }
` 
export const SPreloaderContainer = styled.div`
  display: flex; 
  width: 100%;
  justify-content: center; 
  align-items: center;
`
export const SPreloader = styled.img`
  width: cover; 
  height: 7vh; 
  display: block;
`