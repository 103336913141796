import { Login } from "../pages/auth/Login/Login.lazy";
import { Register } from "../pages/auth/Register/Register.lazy";
import { EmailConfirmation } from "../pages/auth/EmailConfirmation/EmailConfirmation.lazy";
import { ResetPassword } from "../pages/auth/ResetPassword/ResetPassword.lazy";
import { NewPassword } from "../pages/auth/NewPassword/NewPassword.lazy";
import { RecoveryMessage } from "../pages/auth/RecoveryMessage/RecoveryMessage.lazy";

import { Main } from "../pages/content/Main/Main.lazy";
import { Statistic } from "../pages/content/Statistic/Statistic.lazy";
import { KnowledgeBase } from "../pages/content/KnowledgeBase/KnowledgeBase.lazy";
import { Payment } from "../pages/content/Payment/Payment.lazy";
import { Settings } from "../pages/content/Settings/Settings.lazy";
import { Referral } from "../pages/content/Referral/Referral.lazy";
import { Notifications } from "../pages/content/Notifications/Notifications.lazy";
import { EditSite } from "../pages/content/EditSite/EditSite.lazy";
import { AccountConfirmation } from "../pages/auth/AccountConfirmation/AccountConfirmation.lazy";

export const ROUTE_LAYOUTS = {
  AUTH: "AUTH",
  CONTENT: "CONTENT",
};
export const CHILDREN_ROUTES = {
  [ROUTE_LAYOUTS.AUTH]: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "auth/register",
      element: <Register />,
    },
    {
      path: "auth/confirm-email",
      element: <EmailConfirmation />,
    },
    {
      path: "auth/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "auth/account-confirmation",
      element: <AccountConfirmation />,
    },
    {
      path: "auth/recovery-link",
      element: <RecoveryMessage />,
    },
    {
      path: "auth/new-password",
      element: <NewPassword />,
    },
  ],
  [ROUTE_LAYOUTS.CONTENT]: [
    {
      path: "/home",
      element: <Main />,
    },
    {
      path: "statistic",
      element: <Statistic />,
    },
    {
      path: "knowledge-base",
      element: <KnowledgeBase />,
    },
    {
      path: "payment",
      element: <Payment />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
    {
      path: "referral",
      element: <Referral />,
    },
    {
      path: "notifications",
      element: <Notifications />,
    },
    {
      path: "edit-site/:id",
      element: <EditSite />,
    },
  ],
};
