import { createSlice } from "@reduxjs/toolkit";
import { fetchUserSites } from "../thunk/user";
import { LOADING_STATUS } from "../../constants";

const initialState = {
  status: null,
  error: null,
  sites: null,
  searchText: "",
};

const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSites.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });

    builder.addCase(fetchUserSites.fulfilled, (state, { payload }) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.sites = payload;
      state.sitesCount = payload.length;
      return;
    });
    builder.addCase(fetchUserSites.rejected, (state) => {
      state.status = LOADING_STATUS.REJECTED;
    });
  },
});

export const sitesReducer = sitesSlice.reducer;
export const sitesActions = sitesSlice.actions;
