import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUS } from "../../constants";
import {
  fetchArchivedNotifications,
  fetchNotificationsList,
} from "../thunk/notifications";

const initialState = {
  status: null,
  error: null,
  allNotifications: null,
  archived: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    updateNotificationList: (state, { payload }) => {
      const list = payload.isArchived ? "archived" : "allNotifications";
      state[list] = state[list].map((n) => {
        if (n.id === payload.id) {
          return {
            ...n,
            is_read: !n.is_read,
          };
        } else {
          return n;
        }
      });
    },
    archiveNotification: (state, { payload }) => {
      state.allNotifications = state.allNotifications.filter(
        (n) => n.id !== payload.notification.id
      );

      if (state.archived) {
        state.archived = [
          ...state.archived,
          {
            ...payload.notification,
            is_archived: true,
          },
        ];
      }
    },
    recoverNotification: (state, { payload }) => {
      state.archived = state.archived.filter(
        (n) => n.id !== payload.notification.id
      );
      state.allNotifications = [
        ...state.allNotifications,
        { ...payload.notification, is_archived: false },
      ];
    },
    readAll: (state) => {
      state.allNotifications = state.allNotifications.map((n) => ({
        ...n,
        is_read: true,
      }));
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsList.fulfilled, (state, { payload }) => {
      state.status = LOADING_STATUS.RESOLVER;
      state.allNotifications = payload;
      return;
    });
    builder.addCase(fetchNotificationsList.rejected, (state) => {
      state.status = LOADING_STATUS.REJECTED;
    });
    builder.addCase(fetchNotificationsList.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });
    builder.addCase(
      fetchArchivedNotifications.fulfilled,
      (state, { payload }) => {
        state.status = LOADING_STATUS.RESOLVER;
        state.archived = payload;
        return;
      }
    );
    builder.addCase(fetchArchivedNotifications.rejected, (state) => {
      state.status = LOADING_STATUS.REJECTED;
    });
    builder.addCase(fetchArchivedNotifications.pending, (state) => {
      state.status = LOADING_STATUS.LOADING;
      state.error = null;
    });
  },
});

export const notificationsReducer = notificationsSlice.reducer;
export const notificationsActions = notificationsSlice.actions;
