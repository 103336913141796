import axios from 'axios'
import { URLS } from './consts';

const http = axios.create({
  baseURL: `${URLS.SERVER}/`,
})

http.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    console.log(err)
    const originalConfig = err.config
    if (originalConfig.url !== '/' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const {data} = await axios.post(
            `${URLS.SERVER}/users/login`,  
            {
              grant_type: 'refresh_token',
              refresh_token: localStorage.getItem('refresh_token')
            },
            { 
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',  
              },
            }
          )
          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)

          return http(originalConfig)
        } catch (_error) {
          console.log(_error)
          localStorage.removeItem('access_token')
          if (window.location.pathname !== '/') {
            window.location.assign('/')
          }
          return Promise.reject(_error)
        }
      }else {
        return Promise.reject(err)
      }
    }

    return Promise.reject(err)
  }
)
export default http;