import React from "react";
import { InfoIconContainer } from "./styled";
import WhiteInfoIcon from "../../assets/icons/info";
import GreenInfoIcon from "../../assets/icons/green-info";
import TooltipModal from "../../ui/TooltipModal/TooltipModal";
const InfoPopup = ({ children, color, sx }) => {
  return (
    <InfoIconContainer>
      {color === "green" ? (
        <GreenInfoIcon className="cursor-pointer"/>
      ) : (
        <WhiteInfoIcon className="cursor-pointer" />
      )}
      <TooltipModal
        sx={{
          top: "35px",
          right: "-18px",
          minWidth: "11vw",
          width: "100%",
          color: "black",
          fontSize: "14px",
          fontWeight: "500",
          ...sx
        }}
      >
        {children}
      </TooltipModal>
    </InfoIconContainer>
  );
};

export default InfoPopup;
