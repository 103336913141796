import BookClosed from "./assets/icons/book-closed";
import Settings from "./assets/icons/settings";
import PresentationChartIcon from "./assets/icons/presentation-chart";
import RubleIcon from "./assets/icons/currency-ruble-circle";
import GlobeIcon from "./assets/icons/globe";
import UsersIcon from "./assets/icons/users";
import Home from "./assets/icons/home";

export const SIDEBAR_LINKS_ID = {
  SITES: 1,
  STATISTIC: 2,
  PAYMENT: 3,
  KNOWLEDGE_BASE: 4,
  SETTINGS: 5,
  REFERRAL: 6,
};
export const LOADING_STATUS = {
  LOADING: "loading",
  REJECTED: "error",
  RESOLVER: "resolved",
};

export const SITE_TYPE = {
  1: "Одностраничный",
  2: "Многостраничный",
  3: "Интернет магазин",
};

export const SITE_TYPES = [
  {
    id: "ai",
    title: "С помощью ИИ",
    description: `Создание сайта при помощи искусственного интеллекта`,
    getHref: () => "create/ai",
    target: "_self",
  },
  {
    id: "templates",
    title: "Из шаблонов",
    description: `Создание сайта с помощью уже имеющихся шаблонов`,
    getHref: () => {
      return `https://builder.monocreator.ru/?access_token=${localStorage.getItem(
        "access_token"
      )}&creation_type=template`;
    },
    target: "_blank",
  },
  {
    id: "empty",
    title: "С нуля",
    description: `Создание сайта с нуля вручную`,
    inDeveloping: true,
  },
];

export const SIDEBAR_LINKS = [
  {
    id: SIDEBAR_LINKS_ID.SITES,
    icon: <GlobeIcon />,
    title: "Мои сайты",
    link: "/home",
    hasList: true,
  },
  {
    id: SIDEBAR_LINKS_ID.STATISTIC,
    icon: <PresentationChartIcon />,
    title: "Статистика",
    link: "statistic",
    isDisabled: true,
  },
  {
    id: SIDEBAR_LINKS_ID.REFERRAL,
    icon: <UsersIcon />,
    title: "Рефералы",
    link: "referral",
  },
  {
    id: SIDEBAR_LINKS_ID.PAYMENT,
    icon: <RubleIcon />,
    title: "Тарифы и оплата",
    link: "payment",
  },
  {
    id: SIDEBAR_LINKS_ID.KNOWLEDGE_BASE,
    icon: <BookClosed />,
    title: "База знаний",
    link: "knowledge-base",
    isDisabled: true,
  },
  {
    id: SIDEBAR_LINKS_ID.SETTINGS,
    icon: <Settings />,
    title: "Настройки аккаунта",
    link: "settings",
  },
];

export const ERROR_TEXT = {
  ERR_NETWORK: "Ошибка сервера, скоро все починим :(",
  500: "Ошибка сервера, скоро все починим :(",
  ERR_BAD_REQUEST: "Неправильный логин или пароль",
  400: "Неправильный логин или пароль",
  401: "Кажется, вы не авторизованы",
  404: "Кажется, введены неправильные данные :(",
};

export const TARIFF_PLANS_TITLE = {
  1: "Старт",
  2: "Бизнес",
  3: "Агенство",
  4: "Премиум шаблоны",
  5: "Расширенные SEO-инструменты",
};

export const DEVISES_SIZES = {
  MOBILE: 430,
  TABLET: 768,
  DESKTOP: 1024,
  LARGE: 1440,
};

export const PAGE_LINKS_RUS_TITLE = {
  index: {
    title: "Главная",
    pageName: (
      <>
        <Home /> Главная страница
      </>
    ),
  },
  contacts: {
    title: "Контакты",
  },
  about: {
    title: "О нас",
  },
  policy: {
    title: "Политика конфиденциальности",
  },
  services: {
    title: "Услуги",
  },
};
export const LINK_PREFIX = "https://monocreator.ru/";
export const HOME_URL = "/home";

export const NOTIFICATIONS_ID = {
  ALL: "all",
  READ: "read",
  UNREAD: "unread",
  ARCHIVED: "archived",
};

export const CREATION_TYPES = {
  ai: "Сгенерирован AI",
  template: "Созданный из шаблонов",
};

export const MAX_LOGO_NAME_LENGTH = 22;

export const MONO_CREATOR_BUILDER_URL = "http://builder.monocreator.ru/";
