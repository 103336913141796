const Home = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.73673 2.07306C8.47324 1.86812 8.34149 1.76565 8.19601 1.72626C8.06765 1.69151 7.93235 1.69151 7.80399 1.72626C7.65851 1.76565 7.52677 1.86812 7.26327 2.07306L2.17654 6.0294C1.83652 6.29387 1.6665 6.4261 1.54402 6.5917C1.43552 6.73839 1.3547 6.90365 1.30552 7.07935C1.25 7.2777 1.25 7.49308 1.25 7.92385V13.35C1.25 14.1901 1.25 14.6102 1.41349 14.931C1.5573 15.2133 1.78677 15.4427 2.06901 15.5866C2.38988 15.75 2.80992 15.75 3.65 15.75H5.15C5.36002 15.75 5.46503 15.75 5.54525 15.7092C5.61581 15.6732 5.67317 15.6159 5.70913 15.5453C5.75 15.4651 5.75 15.3601 5.75 15.15V10.2C5.75 9.78001 5.75 9.56999 5.83175 9.40956C5.90365 9.26843 6.01839 9.1537 6.15951 9.08179C6.31994 9.00005 6.52996 9.00005 6.95 9.00005H9.05C9.47004 9.00005 9.68006 9.00005 9.84049 9.08179C9.98161 9.1537 10.0963 9.26843 10.1683 9.40956C10.25 9.56999 10.25 9.78001 10.25 10.2V15.15C10.25 15.3601 10.25 15.4651 10.2909 15.5453C10.3268 15.6159 10.3842 15.6732 10.4548 15.7092C10.535 15.75 10.64 15.75 10.85 15.75H12.35C13.1901 15.75 13.6101 15.75 13.931 15.5866C14.2132 15.4427 14.4427 15.2133 14.5865 14.931C14.75 14.6102 14.75 14.1901 14.75 13.35V7.92385C14.75 7.49308 14.75 7.2777 14.6945 7.07935C14.6453 6.90365 14.5645 6.73839 14.456 6.5917C14.3335 6.4261 14.1635 6.29387 13.8235 6.02941L8.73673 2.07306Z"
      stroke="#101018"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Home;
