const Triangle = () => (
  <svg
    width="48"
    height="6"
    viewBox="0 0 48 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Tooltip" clip-path="url(#clip0_1638_26216)">
      <path
        id="bottom-center"
        d="M17.9289 8.01471C17.4835 8.01471 17.2604 7.47614 17.5754 7.16116L23.6464 1.09008C23.8417 0.894821 24.1583 0.894821 24.3536 1.09008L30.4246 7.16116C30.7396 7.47614 30.5165 8.01471 30.0711 8.01471L17.9289 8.01471Z"
        fill="white"
        stroke="#F2F4F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_1638_26216">
        <rect
          width="48"
          height="6"
          fill="white"
          transform="matrix(-1 0 0 -1 48 6)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Triangle;
