const BellNewNotifications = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57795 21C10.2235 21.6224 11.0714 22 12 22C12.9287 22 13.7766 21.6224 14.4221 21M12.9155 2.08389C12.615 2.02841 12.3088 2 12 2C10.5433 2 9.14623 2.63214 8.11616 3.75736C7.0861 4.88258 6.50741 6.4087 6.50741 8C6.50741 11.0902 5.7938 13.206 4.99663 14.6054C4.32421 15.7859 3.988 16.3761 4.00033 16.5408C4.01398 16.7231 4.04934 16.7926 4.18383 16.9016C4.30529 17 4.85284 17 5.94795 17H18.0521C19.1472 17 19.6947 17 19.8162 16.9016C19.9507 16.7926 19.986 16.7231 19.9997 16.5408C20.012 16.3761 19.6758 15.7858 19.0033 14.6052C18.5529 13.8144 18.1291 12.7948 17.8457 11.5"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="17.5" cy="5.5" r="2.5" stroke="#14B464" strokeWidth="2" />
  </svg>
);
export default BellNewNotifications;
