import React, { useEffect, useState } from "react";
import {
  Navigation,
  SDivider,
  SLinkContainerAnim,
  SLinkIcon,
  SLinkLabel,
  SLogo,
  SOpenListBtn,
  SSidebar,
  SSidebarButton,
  SSiteBtn,
  SSitesList,
  StyledNavLink,
} from "./styled";
import { SIDEBAR_LINKS } from "../../constants";
import AsideLogo from "../../assets/icons/aside-logo";
import MonoCreatorLogo from "../../assets/icons/mono-creator-logo";
import { useDispatch, useSelector } from "react-redux";
import ChevronUp from "../../assets/icons/chevron-up";
import ChevronDown from "../../assets/icons/chevron-down";
import { useParams } from "react-router-dom";
import { fetchUserSites } from "../../redux/thunk/user";

const CustomToolTip = ({
  disabled,
  children,
  text,
  selected,
  onClick,
  isDisabled,
}) => (
  <SLinkContainerAnim
    disableHoverListener={disabled}
    placement="right"
    isActive={disabled}
    title={text}
    arrow
    tabIndex="0"
    selected={selected}
    onClick={onClick}
    textLength={text.length}
    isDisabled={isDisabled}
  >
    {children}
  </SLinkContainerAnim>
);

const Sidebar = ({ setSelectedSection, selectedSection }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { sites } = useSelector((state) => state.sites);
  const [sitesListOpen, setSitesListOpen] = useState(false);
  const { id: urlId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!sites) {
      dispatch(fetchUserSites());
    }
  }, []);

  const openSitesList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSitesListOpen((p) => !p);
  };
  return (
    <SSidebar isOpen={sidebarOpen} theme={{ bg: "white" }}>
      <SSidebarButton
        isOpen={sidebarOpen}
        onClick={() => {
          setSidebarOpen((p) => !p);
          setSitesListOpen(false);
        }}
      />

      <SLogo isActive={sidebarOpen} to='/home'>
        <div>
          <AsideLogo />
        </div>
        <MonoCreatorLogo />
      </SLogo>

      <Navigation isActive={sidebarOpen}>
        {SIDEBAR_LINKS.map(({ icon, title, id, link, isDisabled, hasList }) =>
          hasList ? (
            <div className="d-flex flex-column">
              <CustomToolTip
                disabled={sidebarOpen}
                key={title}
                text={title}
                selected={link.includes(selectedSection)}
                link={link}
                onClick={() => !isDisabled && setSelectedSection(link)}
                isDisabled={isDisabled}
              >
                <StyledNavLink to={link}>
                  <SLinkIcon>{icon}</SLinkIcon>
                  <SLinkLabel>{title}</SLinkLabel>
                  {sites?.length > 0 && (
                    <SOpenListBtn onClick={openSitesList}>
                      {sitesListOpen ? <ChevronUp /> : <ChevronDown />}
                    </SOpenListBtn>
                  )}
                </StyledNavLink>
              </CustomToolTip>
              <SSitesList>
                {sitesListOpen &&
                  sites?.map((el) => (
                    <SSiteBtn
                      key={el.id}
                      to={`edit-site/${el.id}`}
                      selected={el.id == urlId}
                    >
                      {el.company_name}
                    </SSiteBtn>
                  ))}
              </SSitesList>
            </div>
          ) : (
            <CustomToolTip
              disabled={sidebarOpen}
              key={title}
              text={title}
              selected={link.includes(selectedSection)}
              link={link}
              onClick={() => !isDisabled && setSelectedSection(link)}
              isDisabled={isDisabled}
            >
              {isDisabled ? (
                <div>
                  <SLinkIcon>{icon}</SLinkIcon>
                  <SLinkLabel>{title}</SLinkLabel>
                </div>
              ) : (
                <StyledNavLink to={link}>
                  <SLinkIcon>{icon}</SLinkIcon>
                  <SLinkLabel>{title}</SLinkLabel>
                </StyledNavLink>
              )}
            </CustomToolTip>
          )
        )}
      </Navigation>
      <SDivider />
    </SSidebar>
  );
};

export default Sidebar;
